<template>
  <div class="home">
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1">
          <h1><img src="../assets/logo.png" alt="" width="100%"></h1>
          <p class="1">数据资产入表咨询服务项目简介</p>
          <span>www.51haohuo.com</span>
        </div>
        <div :class="`swiper-slide slide2 slideHead ${animationList.indexOf('slide2Animation') > -1 ? 'slide2Animation' : ''}`">
          <div class="head">
            <p>公司简介</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="title">国内有影响力的数字科技+数字经济创新服务企业</div>
          <div class="showImg"><img src="../assets/slide2Img.png" alt=""></div>
          <div class="content">
            <h4><span>价值观</span> 合规成就品牌，科技铸就未来</h4>
            <p>好活（重庆）网络科技有限公司成立于2022年，是一家以大数据、云计算、区块链、人工智能等数字技术驱动的数字经济创新服务科技企业，重点聚焦数字经济领域，提供数字化就业服务、产业数字化服务、数据要素生态产业园建设与运营、数据要素人才产教融合培养、数据资产化综合服务。服务企业从数字化改造升级为入口，构建以形成有价值数据资产为目标的数据要素化平台体系，帮助传统产业实现数据要素化升级。
            </p>
          </div>
        </div>
        <div :class="`swiper-slide slide3 slideHead ${animationList.indexOf('slide3Animation') > -1 ? 'slide3Animation' : ''}`">
          <div class="head">
            <p>项目介绍</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="content">
            <div class="contentBox">
              <h1>数据资产入表咨询服务</h1>
              <p>
                <b>数据资源盘点编目</b>
                <span>对企业持有的数据资源进行全面梳理和盘点，明确待入表数据资源的来源、类型、权属、数量、质量、使用情况等信息，编制企业数据资源目录。</span>
              </p>
              <p>
                <b>数据资源合规和权属评估</b>
                <span>对企业持有和控制的数据资源的合规性进行评估；对企业持有和控制的数据资源的持有权、加工使用权、经营权等权属进行评估。</span>
              </p>
              <p>
                <b>数据资源入表辅导</b>
                <span>辅导财务部门对拟入表数据资源的相关交易和事项进行会计确认、计量和报告，并提供财会相关法律法规、政策规定以及“指引”的解读</span>
              </p>
            </div>
          </div>
          <div class="slide3Bg"></div>
          <img src="../assets/slide3Bg.png" alt="" class="slide3BgImg">
        </div>
        <div :class="`swiper-slide slide4 slideHead ${animationList.indexOf('slide4Animation') > -1 ? 'slide4Animation' : ''}`">
          <div class="head">
            <p>服务对象</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="whitBg"></div>
          <div class="content">
            <div class="centerRound">
              <div class="line1"><img src="../assets/line1.png" width="100%" alt=""></div>
              <div class="line2"><img src="../assets/line2.png" width="100%" alt=""></div>
              <div class="bigRund">
                <div class="minRund">
                  <p></p>
                  <b>我们服务</b>
                </div>
              </div>
            </div>
            <div class="itemBox">
              <div class="itemBoxOne">
                <div class="item item1">
                  <p>满足地方政府对于国有企业数据资源盘点编目，数据资产入表、确权登记和运营等数据资产管理要求</p>
                  <h4>国有企业</h4>
                  <i></i>
                </div>
                <div class="item item2">
                  <p>应满足财务合规要求，使数据资源在企业报表中得到合理体现，反映相应的资产和利润成果</p>
                  <h4>上市公司</h4>
                  <i></i>
                </div>
              </div>
              <div class="itemBoxOne">
                <div class="item item3">
                  <h4>行政事业单位</h4>
                  <p>响应国家政策号召，满足财政部对于行政事业单位数据资产纳入管理的要求</p>
                  <i></i>
                </div>
                <div class="item item4">
                  <h4>数据资源型企业</h4>
                  <p>体现企业稀缺性和排他性数据资源的禀赋，彰显企业数据价值挖掘和数据资产化潜力</p>
                  <i></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="`swiper-slide slide5 slideHead ${animationList.indexOf('slide5Animation') > -1 ? 'slide5Animation' : ''}`">
          <div class="head">
            <p>服务价值</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <div class="whitBg"></div>
          <div class="content">
            <div class="icons">
              <div class="iconsItem flexStart">
                <div class="item">
                  <img src="../assets/slide5icon1.png" alt="">
                </div>
                <span>降低资产负债比</span>
                <i></i>
              </div>
              <div class="iconsItem flexEnd">
                <span>提升净利润</span>
                <div class="item">
                  <img src="../assets/slide5icon2.png" alt="">
                </div>
                <i></i>
              </div>
              <div class="iconsItem flexStart">
                <div class="item">
                  <img src="../assets/slide5icon3.png" alt="">
                </div>
                <span>债权/股权融资</span>
                <i></i>
              </div>
              <div class="iconsItem flexEnd">
                <span>启动企业数据战略</span>
                <div class="item">
                  <img src="../assets/slide5icon4.png" alt="">
                </div>
                <i></i>
              </div>
              <div class="iconsItem flexStart">
                <div class="item">
                  <img src="../assets/slide5icon5.png" alt="">
                </div>
                <span>响应新质生产力发展方针</span>
                <i></i>
              </div>
              <!-- <div class="iconsItem flexEnd">
                <span>经营数据开拓变现路径</span>
                <div class="item">
                  <img src="../assets/slide5icon6.png" alt="">
                </div>
                <i></i>
              </div> -->
            </div>
          </div>
        </div>
        <div class="swiper-slide slide6 slideHead sildeNoBg">
          <div class="head">
            <p>数据要素领域专业资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgData">
              <div class="contentText">
                <p>好活（重庆）网络科技有限公司</p>
                <p>获多地数据交易所数商认证</p>
              </div>
              <div class="imgItem">
                <b><img src="../assets/slide6Img1.png" alt=""/></b>
                <p>西部大数据交易中心<span>数据商</span></p>
              </div>
              <div class="imgItem">
                <b><img src="../assets/slide6Img2.png" alt=""/></b>
                <p>深圳大数据交易所<span>数据商</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide6 slideHead sildeNoBg">
          <div class="head">
            <p>数据要素领域专业资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgData">
              <div class="imgItem">
                <b><img src="../assets/slide6Img12.png" alt=""/></b>
                <p>贵阳大数据交易所<span>技术型数据商</span>和<span>应用型数据商</span></p>
              </div>
              <div class="imgItem">
                <b><img src="../assets/slide6Img13.png" alt=""/></b>
                <p>贵阳大数据交易所<span>数据中介</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide7 slideHead sildeNoBg">
          <div class="head">
            <p>自主知识产权</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgData">
              <div class="contentText">
                <p>好活集团取得：</p>
                <p>发明专利<span>27个</span>，实用新型<span>142个</span>，软著<span>402个</span></p>
              </div>
              <div class="imgItem">
                <img src="../assets/slide7Img12.png" alt=""/>
                <img src="../assets/slide7Img12.png" alt=""/>
                <img src="../assets/slide7Img12.png" alt=""/>
                <img src="../assets/slide7Img12.png" alt=""/>
                <img src="../assets/slide7Img12.png" alt=""/>
                <img src="../assets/slide7Img12.png" alt=""/>
                <img src="../assets/slide7Img12.png" alt=""/>
              </div>
              <div class="imgItem">
                <img src="../assets/slide7Img13.png" alt=""/>
                <img src="../assets/slide7Img13.png" alt=""/>
                <img src="../assets/slide7Img13.png" alt=""/>
                <img src="../assets/slide7Img13.png" alt=""/>
                <img src="../assets/slide7Img13.png" alt=""/>
                <img src="../assets/slide7Img13.png" alt=""/>
                <img src="../assets/slide7Img13.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slideHead sildeNoBg">
          <div class="head">
            <p>荣誉资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img2.png" alt=""/>
              <p>被评为<span>“2021中国服务业企业500强”</span></p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img3.png" alt=""/>
              <p>被评为<span>“AAA级信用企业”</span></p>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slideHead sildeNoBg">
          <div class="head">
            <p>荣誉资质</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img5.png" alt=""/>
              <p>入选<span>DAC全球数据资产理事会</span></p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img6.png" alt=""/>
              <p>入选<span>数据要素市场化配置改革先进示范模式</span></p>
            </div>
          </div>
        </div>
        <!-- <div class="swiper-slide slide8 slide9 slideHead sildeNoBg">
          <div class="head">
            <p>奖项荣誉</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img7.png" alt=""/>
              <p>2020年11月深圳人力资源数字化科技大赛，好活科</p>
              <p>技荣获应用赛二等奖</p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img8.png" alt=""/>
              <p>2021年5月，数博会领先科技成果发布活动，好活</p>
              <p>科技荣获“2021领先科技成果优秀项目”荣誉</p>
            </div>
          </div>
        </div>
        <div class="swiper-slide slide8 slide9 slideHead sildeNoBg">
          <div class="head">
            <p>奖项荣誉</p>
            <img src="../assets/logo.png" alt="">
          </div>
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="imgItem">
              <img src="../assets/slide8Img9.png" alt=""/>
              <p>2021年12月，广东“众创杯”创业创新大赛，好活科</p>
              <p>技暂获科技海归领航赛金奖</p>
            </div>
            <div class="imgItem">
              <img src="../assets/slide8Img10.png" alt=""/>
              <p>2024年8月，在“2024全球数据资产大会”上，好活</p>
              <p>科技荣获“数据要素市场化配置改革先进示范模式”十</p>
              <p>佳荣誉，成为数据要素行业发展的典范。</p>
            </div>
          </div>
        </div> -->
        <div class="swiper-slide slide10 sildeNoBg">
          <img src="../assets/slide6Bg2.png" alt="" class="img"/>
          <div class="content">
            <div class="logo">
              <img src="../assets/logo.png" alt="">
              <div class="text">
                <p>数据要素产业园建设与运营</p>
                <p>数据资产化服务</p>
                <p>数据技术服务</p>
                <p>公共数据运营赋能</p>
              </div>
            </div>
            <div class="QRCode">
              <div class="itemImg">
                <div class="item">
                  <img src="../assets/qrcodeImg1.png" alt="">
                </div>
              </div>
              <div class="tips">
                <h1>扫一扫，查看公众号</h1>
                <p>好活（重庆）网络科技有限公司</p>
              </div>
            </div>
            <div class="bottom">
              <p><i></i>重庆两江新区寸滩街道金渝大道153号4幢</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tipsIcons">
      <img src="../assets/icon1.png" alt="" class="iconsImg1">
      <img src="../assets/icon1.png" alt="" class="iconsImg2">
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'HomeView',
  data() {
    return {
      animationList: [],
      animationObj: {
        0:'slide1Animation',
        1:'slide2Animation',
        2:'slide3Animation',
        3:'slide4Animation',
        4:'slide5Animation',
        5:'slide6Animation',
        6:'slide7Animation',
        7:'slide8Animation',
        8:'slide9Animation',
        9:'slide10Animation',
        10:'slide11Animation'
      },
    }
  },
  mounted() {
    const that = this;
    var mySwiper = new Swiper ('.swiper', {
      initialSlide: 0,
      direction: 'vertical', // 垂直切换选项
      loop: false, // 循环模式选项
      on:{
        slideChange: function(e){
          if (that.animationList.indexOf(that.animationObj[this.activeIndex]) == -1) {
            that.animationList.push(that.animationObj[this.activeIndex])
          }
        },
      },
  })  
  },
  components: {
  },
};
</script>
<style>
@import url(../assets/style.css);
@import url(../assets/animation.css);
</style>
