var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._m(0),_c('div',{class:`swiper-slide slide2 slideHead ${_vm.animationList.indexOf('slide2Animation') > -1 ? 'slide2Animation' : ''}`},[_vm._m(1),_c('div',{staticClass:"title"},[_vm._v("国内有影响力的数字科技+数字经济创新服务企业")]),_vm._m(2),_vm._m(3)]),_c('div',{class:`swiper-slide slide3 slideHead ${_vm.animationList.indexOf('slide3Animation') > -1 ? 'slide3Animation' : ''}`},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"slide3Bg"}),_c('img',{staticClass:"slide3BgImg",attrs:{"src":require("../assets/slide3Bg.png"),"alt":""}})]),_c('div',{class:`swiper-slide slide4 slideHead ${_vm.animationList.indexOf('slide4Animation') > -1 ? 'slide4Animation' : ''}`},[_vm._m(6),_c('div',{staticClass:"whitBg"}),_vm._m(7)]),_c('div',{class:`swiper-slide slide5 slideHead ${_vm.animationList.indexOf('slide5Animation') > -1 ? 'slide5Animation' : ''}`},[_vm._m(8),_c('div',{staticClass:"whitBg"}),_vm._m(9)]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)])]),_vm._m(16)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide1"},[_c('h1',[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"","width":"100%"}})]),_c('p',{staticClass:"1"},[_vm._v("数据资产入表咨询服务项目简介")]),_c('span',[_vm._v("www.51haohuo.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("公司简介")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showImg"},[_c('img',{attrs:{"src":require("../assets/slide2Img.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h4',[_c('span',[_vm._v("价值观")]),_vm._v(" 合规成就品牌，科技铸就未来")]),_c('p',[_vm._v("好活（重庆）网络科技有限公司成立于2022年，是一家以大数据、云计算、区块链、人工智能等数字技术驱动的数字经济创新服务科技企业，重点聚焦数字经济领域，提供数字化就业服务、产业数字化服务、数据要素生态产业园建设与运营、数据要素人才产教融合培养、数据资产化综合服务。服务企业从数字化改造升级为入口，构建以形成有价值数据资产为目标的数据要素化平台体系，帮助传统产业实现数据要素化升级。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("项目介绍")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"contentBox"},[_c('h1',[_vm._v("数据资产入表咨询服务")]),_c('p',[_c('b',[_vm._v("数据资源盘点编目")]),_c('span',[_vm._v("对企业持有的数据资源进行全面梳理和盘点，明确待入表数据资源的来源、类型、权属、数量、质量、使用情况等信息，编制企业数据资源目录。")])]),_c('p',[_c('b',[_vm._v("数据资源合规和权属评估")]),_c('span',[_vm._v("对企业持有和控制的数据资源的合规性进行评估；对企业持有和控制的数据资源的持有权、加工使用权、经营权等权属进行评估。")])]),_c('p',[_c('b',[_vm._v("数据资源入表辅导")]),_c('span',[_vm._v("辅导财务部门对拟入表数据资源的相关交易和事项进行会计确认、计量和报告，并提供财会相关法律法规、政策规定以及“指引”的解读")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("服务对象")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"centerRound"},[_c('div',{staticClass:"line1"},[_c('img',{attrs:{"src":require("../assets/line1.png"),"width":"100%","alt":""}})]),_c('div',{staticClass:"line2"},[_c('img',{attrs:{"src":require("../assets/line2.png"),"width":"100%","alt":""}})]),_c('div',{staticClass:"bigRund"},[_c('div',{staticClass:"minRund"},[_c('p'),_c('b',[_vm._v("我们服务")])])])]),_c('div',{staticClass:"itemBox"},[_c('div',{staticClass:"itemBoxOne"},[_c('div',{staticClass:"item item1"},[_c('p',[_vm._v("满足地方政府对于国有企业数据资源盘点编目，数据资产入表、确权登记和运营等数据资产管理要求")]),_c('h4',[_vm._v("国有企业")]),_c('i')]),_c('div',{staticClass:"item item2"},[_c('p',[_vm._v("应满足财务合规要求，使数据资源在企业报表中得到合理体现，反映相应的资产和利润成果")]),_c('h4',[_vm._v("上市公司")]),_c('i')])]),_c('div',{staticClass:"itemBoxOne"},[_c('div',{staticClass:"item item3"},[_c('h4',[_vm._v("行政事业单位")]),_c('p',[_vm._v("响应国家政策号召，满足财政部对于行政事业单位数据资产纳入管理的要求")]),_c('i')]),_c('div',{staticClass:"item item4"},[_c('h4',[_vm._v("数据资源型企业")]),_c('p',[_vm._v("体现企业稀缺性和排他性数据资源的禀赋，彰显企业数据价值挖掘和数据资产化潜力")]),_c('i')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('p',[_vm._v("服务价值")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"icons"},[_c('div',{staticClass:"iconsItem flexStart"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon1.png"),"alt":""}})]),_c('span',[_vm._v("降低资产负债比")]),_c('i')]),_c('div',{staticClass:"iconsItem flexEnd"},[_c('span',[_vm._v("提升净利润")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon2.png"),"alt":""}})]),_c('i')]),_c('div',{staticClass:"iconsItem flexStart"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon3.png"),"alt":""}})]),_c('span',[_vm._v("债权/股权融资")]),_c('i')]),_c('div',{staticClass:"iconsItem flexEnd"},[_c('span',[_vm._v("启动企业数据战略")]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon4.png"),"alt":""}})]),_c('i')]),_c('div',{staticClass:"iconsItem flexStart"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/slide5icon5.png"),"alt":""}})]),_c('span',[_vm._v("响应新质生产力发展方针")]),_c('i')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide6 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("数据要素领域专业资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgData"},[_c('div',{staticClass:"contentText"},[_c('p',[_vm._v("好活（重庆）网络科技有限公司")]),_c('p',[_vm._v("获多地数据交易所数商认证")])]),_c('div',{staticClass:"imgItem"},[_c('b',[_c('img',{attrs:{"src":require("../assets/slide6Img1.png"),"alt":""}})]),_c('p',[_vm._v("西部大数据交易中心"),_c('span',[_vm._v("数据商")])])]),_c('div',{staticClass:"imgItem"},[_c('b',[_c('img',{attrs:{"src":require("../assets/slide6Img2.png"),"alt":""}})]),_c('p',[_vm._v("深圳大数据交易所"),_c('span',[_vm._v("数据商")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide6 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("数据要素领域专业资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgData"},[_c('div',{staticClass:"imgItem"},[_c('b',[_c('img',{attrs:{"src":require("../assets/slide6Img12.png"),"alt":""}})]),_c('p',[_vm._v("贵阳大数据交易所"),_c('span',[_vm._v("技术型数据商")]),_vm._v("和"),_c('span',[_vm._v("应用型数据商")])])]),_c('div',{staticClass:"imgItem"},[_c('b',[_c('img',{attrs:{"src":require("../assets/slide6Img13.png"),"alt":""}})]),_c('p',[_vm._v("贵阳大数据交易所"),_c('span',[_vm._v("数据中介")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide7 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("自主知识产权")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgData"},[_c('div',{staticClass:"contentText"},[_c('p',[_vm._v("好活集团取得：")]),_c('p',[_vm._v("发明专利"),_c('span',[_vm._v("27个")]),_vm._v("，实用新型"),_c('span',[_vm._v("142个")]),_vm._v("，软著"),_c('span',[_vm._v("402个")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img12.png"),"alt":""}})]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/slide7Img13.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide8 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("荣誉资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img2.png"),"alt":""}}),_c('p',[_vm._v("被评为"),_c('span',[_vm._v("“2021中国服务业企业500强”")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img3.png"),"alt":""}}),_c('p',[_vm._v("被评为"),_c('span',[_vm._v("“AAA级信用企业”")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide8 slideHead sildeNoBg"},[_c('div',{staticClass:"head"},[_c('p',[_vm._v("荣誉资质")]),_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img5.png"),"alt":""}}),_c('p',[_vm._v("入选"),_c('span',[_vm._v("DAC全球数据资产理事会")])])]),_c('div',{staticClass:"imgItem"},[_c('img',{attrs:{"src":require("../assets/slide8Img6.png"),"alt":""}}),_c('p',[_vm._v("入选"),_c('span',[_vm._v("数据要素市场化配置改革先进示范模式")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide slide10 sildeNoBg"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/slide6Bg2.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}}),_c('div',{staticClass:"text"},[_c('p',[_vm._v("数据要素产业园建设与运营")]),_c('p',[_vm._v("数据资产化服务")]),_c('p',[_vm._v("数据技术服务")]),_c('p',[_vm._v("公共数据运营赋能")])])]),_c('div',{staticClass:"QRCode"},[_c('div',{staticClass:"itemImg"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/qrcodeImg1.png"),"alt":""}})])]),_c('div',{staticClass:"tips"},[_c('h1',[_vm._v("扫一扫，查看公众号")]),_c('p',[_vm._v("好活（重庆）网络科技有限公司")])])]),_c('div',{staticClass:"bottom"},[_c('p',[_c('i'),_vm._v("重庆两江新区寸滩街道金渝大道153号4幢")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tipsIcons"},[_c('img',{staticClass:"iconsImg1",attrs:{"src":require("../assets/icon1.png"),"alt":""}}),_c('img',{staticClass:"iconsImg2",attrs:{"src":require("../assets/icon1.png"),"alt":""}})])
}]

export { render, staticRenderFns }